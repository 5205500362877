import { db, auth, firebase, app } from "../../../../firebase";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  addDoc,
  onSnapshot,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

export const getUserProfile = async (user) => {
  const docRef = doc(db, "userAccounts", user.uid);
  const userInfo = await getDoc(docRef);

  return userInfo.data();
};

export const getAllTools = async () => {
  const allTools = {};
  const colRef = collection(db, "products");
  try {
    await getDocs(colRef).then((col) => {
      col.forEach(async (products) => {
        // This gets the products from stripe
        const priceRef = collection(colRef, products.id, "prices");

        if (products.data().name !== "Vekta-Cables_Single-User") {
          await getDocs(priceRef).then((prices) => {
            // This gets all the pricing information for the product
            const allPrices = [];
            prices.forEach((price) => {
              let priceData = price.data();
              if (priceData.active) {
                priceData["id"] = price.id;
                allPrices.push(priceData);
              }
            });

            var temp = products.data();
            temp["id"] = products.id;

            allTools[products.id] = {
              info: temp,
              prices: allPrices,
            };
          });
        }
      });
    });
  } catch (error) {
    console.log(error);
  }
  return allTools;
};

export const getSubUsers = async (user) => {
  const subUsers = {};
  const docRef = doc(db, "userAccounts", user.uid);
  const userInfo = await getDoc(docRef);

  userInfo.data().sub_users.forEach(async (tool) => {
    const userInfo = await getDoc(tool);

    subUsers[userInfo.id] = userInfo.data();
  });
  return subUsers;
};

export const subscribeToTool = async (user, tool) => {
  const userRef = doc(db, "userAccounts", user.uid);
  const toolRef = doc(db, "vektaStore", tool);

  await updateDoc(userRef, {
    owned_tools: arrayUnion(toolRef),
  });
};

export const unsubscribeToTool = async (user, tool) => {
  const userRef = doc(db, "userAccounts", user.uid);
  const toolRef = doc(db, "vektaStore", tool);

  await updateDoc(userRef, {
    owned_tools: arrayRemove(toolRef),
  });
};

export const getSharingGroup = async (cuid) => {
  const sharingRef = doc(db, "userSharing", cuid);

  return await getDoc(sharingRef);
};

export const getCheckoutUrl = async (priceId) => {
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error("User is not authenticated");

  const checkoutSessionRef = collection(
    db,
    "customers",
    userId,
    "checkout_sessions"
  );

  var document;
  // if (min === '1' && max === '1') {
  // document = {
  //     price: priceId,
  //     success_url: "https://vekta-digitalplus.web.app/", // go to vekta digital + website
  //     cancel_url: window.location.href, // go to vekta digital website
  // }
  // } else {
  document = {
    line_items: [
      {
        price: priceId,
        adjustable_quantity: {
          enabled: true,
          maximum: 50,
          minimum: 1,
        },
        quantity: 1,
      },
    ],
    automatic_tax: true,
    mode: "subscription",
    success_url: "http://localhost:3001/", // go to vekta digital + website
    cancel_url: window.location.href, // go to vekta digital website
  };
  // }

  const docRef = await addDoc(checkoutSessionRef, document);

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(docRef, (snap) => {
      const url = snap.data();
      // if (error) {
      //     unsubscribe();
      //     reject(new Error(`An error occurred: ${error.message}`));
      // }
      if (url.url) {
        unsubscribe();
        resolve(url);
      }
    });
  });
};

export const getPortalUrl = async () => {
  const user = auth.currentUser;

  let dataWithUrl;
  try {
    const functions = getFunctions(app, "europe-west2");
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );

    const { data } = await functionRef({
      returnUrl: window.location.origin,
      configuration: "",
    });

    // Add a type to the data
    dataWithUrl = data;
  } catch (error) {
    console.error(error);
  }

  return new Promise((resolve, reject) => {
    if (dataWithUrl.url) {
      resolve(dataWithUrl.url);
    } else {
      reject(new Error("No url returned"));
    }
  });
};

export const validateProductKey = async (key) => {
  const user = auth.currentUser;
  let dataWithUrl;
  try {
    const functions = getFunctions(app, "us-central1");
    const functionRef = httpsCallable(functions, "validateKey");

    const valid = (await functionRef({ key: key })).data

    return valid;
  } catch (error) {
    console.error(error);
  }
};

export const createNewProject = async (data) => {
  const user = auth.currentUser;

  try {
    const functions = getFunctions(app, "us-central1");
    const functionRef = httpsCallable(functions, "newProjectGroup");
    const formatted = [];

    for (let i = 0; i < data.users.length; i++) {
      formatted.push(JSON.parse(data.users[i]));
    }

    await functionRef({
      admin: data.admin,
      users: formatted,
      project: data.name,
    }).then((result) => {
      return result.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const removeUser = async (uid, companyId) => {
  const user = auth.currentUser;

  try {
    const functions = getFunctions(app, "us-central1");
    const functionRef = httpsCallable(functions, "removeUser");

    await functionRef({ user: uid, companyId: companyId }).then((result) => {
      return result.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const deactivateLicense = async (data) => {
  const user = auth.currentUser;

  try {
    const functions = getFunctions(app, "us-central1");
    const functionRef = httpsCallable(functions, "newProjectGroup");
    const formatted = [];

    for (let i = 0; i < data.users.length; i++) {
      formatted.push(JSON.parse(data.users[i]));
    }

    await functionRef({
      admin: data.admin,
      users: formatted,
      project: data.name,
    }).then((result) => {
      return result.data;
    });
  } catch (error) {
    console.error(error);
  }
};
