import React, { useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

function ContextMenu({ status, item, reset, sectionOne, sectionTwo }) {
  // const [settingsMenu, setSettingsMenu] = useState({ open: null, row: null })

  return (
    <Menu
      id="menu"
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "right",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      // anchorEl={status.source}
      anchorEl={status}
      dense
      keepMounted
      // open={status.open}
      open={Boolean(status)}
      onClose={() => reset(item)}
    >
      {sectionOne.map((item) => {
        return (
          <MenuItem
            style={{ fontFamily: "Montserrat" }}
            onClick={item.callback}
          >
            {item.name}
          </MenuItem>
        );
      })}

      {sectionOne.length > 0 && <Divider />}

      {sectionTwo.map((item) => {
        return (
          <MenuItem
            style={{ fontFamily: "Montserrat" }}
            onClick={item.callback}
          >
            {item.name}
          </MenuItem>
        );
      })}
    </Menu>
  );
}

export default ContextMenu;
