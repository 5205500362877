import React, { useState } from "react";
import styled from "styled-components";
import { validateProductKey } from "../helperFunctions";
import { InputMask } from "primereact/inputmask";
import { BounceLoader } from "react-spinners";
import { ModalClose, PrimeButton } from "../../../../../styles/styles";
import { Divider } from "@mui/material";

const Container = styled.div`
  position: absolute;
  inset: 35% 35%;
  background: white;
  padding: 24px;
  transition: ease-in 2s;
  border-radius: 8px;
  box-shadow: 0px 0px 20px #a4a4a4;
`;

const InfoPrompt = styled("span")`
  width: 95%;
  height: 30px;
  padding: 8px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) =>
    props.success ? "rgba(0, 196, 84, 1)" : "rgb(206, 69, 69)"};

  display: flex;
  align-items: center;
  justify-content: center;
`;

function ProductKeyEntry({ info, setSidePanel }) {
  const [productKey, setProductKey] = useState();
  const [validated, setValidated] = useState({
    shown: false,
    success: true,
    message: "",
  });

  return (
    <Container
      style={{
        zIndex: "1000",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
      className="productKey_entry"
    >
      {validated.shown && (
        <InfoPrompt success={validated.success}>{validated.message}</InfoPrompt>
      )}

      <div
        className="popup_heading"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Product Key Activation</h2>

          <ModalClose onClick={() => setSidePanel({ open: false, type: null })}>
            X
          </ModalClose>
        </div>

        <Divider />
      </div>

      <div className="popup_body" style={{ margin: "0" }}>
        <div className="input">
          {/* <label>Product Key</label>
                    <input onChange={(e) => setProductKey(e.target.value)} type='text' placeholder='e.g. ' /> */}
          <span
            className="p-float-label"
            style={{ width: "100%", height: "6rem" }}
          >
            <InputMask
              id="productKey_input"
              value={productKey}
              onChange={(e) => {
                setValidated({ shown: false, success: true, message: "" });
                setProductKey(e.target.value);
              }}
              mask="********-****-****-****-************"
              style={{ width: "100%", height: "6rem" }}
            />
            <label htmlFor="productKey_input">Product Key</label>
          </span>
        </div>
      </div>

      <footer style={{ display: "flex", justifyContent: "flex-end" }}>
        <PrimeButton
          style={{ width: "fit-content" }}
          onClick={async () => {
            const valid = await validateProductKey(productKey);
            let message = "Valid Product Key";

            console.log({ valid });

            if (!valid) message = "Invalid Product Key - Try again";

            setValidated({ shown: true, success: valid, message: message });
            window.location.reload();
          }}
        >
          Submit
        </PrimeButton>
      </footer>
    </Container>
  );
}

export default ProductKeyEntry;
