import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getCheckoutUrl } from "../helperFunctions";
import PricingHomeOne from "../../../../../landing/pricingScetion/PricingHomeOne";

import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPeopleGroup as TeamIcon,
  faBolt as FreeIcon,
  faCircleCheck as CheckIcon,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faComments as ChatIcon } from "@fortawesome/free-regular-svg-icons";

import EnterpiseIcon from "../../../../../media/enterpiseIcon.png";

import Badge from "@mui/material/Badge";
import { BeatLoader } from "react-spinners";
import { ModalClose, PrimeButton } from "../../../../../styles/styles";
import { getCablesTools } from "../../../../../landing/helperFunctions";
import { Divider } from "@mui/material";
import { InputSwitch } from "primereact/inputswitch";
import { doc, getDoc } from "firebase/firestore";
import { app, db } from "../../../../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const Container = styled.div`
  position: absolute;
  /* inset: 40px 40px 40px 35%; */
  inset: 10%;
  background: white;
  padding: 18px;
  transition: ease-in 2s;
  border-radius: 8px;
  box-shadow: 0px 0px 20px #a4a4a4;
`;
const StandardContainerColumn = styled("div")`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
`;

const StandardContainerRow = styled("div")`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px;
  gap: 16px;
`;

const Feature = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin: 8px;

  p {
    margin: 0;
  }
`;

const StyledBadge = styled(Badge)((props) => ({
  "& .MuiBadge-badge": {
    right: props.type === "deal" ? "90px" : "65px",
    top: "2px",
    background:
      props.type === "deal"
        ? "linear-gradient(90deg, rgba(0,232,255,1) 0%, rgba(255,0,254,1) 100%)"
        : "#007592",
    padding: "8px",
    fontSize: "1.5rem",
    color: "white",
  },
}));

function InstallTool({ info, setSidePanel, sidePanel }) {
  const [loading, setLoading] = useState(false);
  const [toolInfo, setToolInfo] = useState({});
  const [frequency, setFrequency] = useState("month");
  const [paymentOption, setPaymentOption] = useState({
    month: { price: 50, priceID: "price_1QHwCOIowKhyLz4fNDOhqYjx" },
    year: { price: 550, priceID: "price_1QHwCOIowKhyLz4fOashrMgA" },
  });
  const [activatedDeals, setActivatedDeals] = useState(0);

  const handleProductPurchase = async (priceId) => {
    const checkoutUrl = await getCheckoutUrl(priceId);
    setLoading(false);
    window.location.href = checkoutUrl.url;
  };

  const formatPrice = (price) => {
    let splitName = String(price).split("");

    let last = splitName.pop();
    let secondLast = splitName.pop();

    return (
      new Intl.NumberFormat().format(splitName.join("")) +
      "." +
      secondLast +
      last
    );
  };

  useEffect(() => {
    const checkActivations = async () => {
      const activated_deals = (
        await getDoc(doc(db, "accessControl", "active_deals"))
      ).data().active_deals;

      setActivatedDeals(activated_deals.length);
    };

    checkActivations();
  }, []);

  return (
    <Container style={{ zIndex: "1000" }} className="installTool">
      <div className="popup_heading">
        <h2>Purchase Options</h2>
        <ModalClose onClick={() => setSidePanel({ open: false, type: null })}>
          X
        </ModalClose>
      </div>

      <Divider />

      <div
        style={{
          display: "flex",
          gap: "8px",
          alignItems: "center",
          justifyContent: "center",
          margin: "8px",
        }}
      >
        <label style={{ margin: "0" }}>Monthly</label>
        <InputSwitch
          checked={frequency === "year"}
          onChange={(e) => setFrequency(e.value ? "year" : "month")}
        />
        <label style={{ margin: "0" }}>Annually</label>
      </div>

      <StandardContainerRow
        style={{ height: "90%", width: "70%", margin: "auto" }}
      >
        <StyledBadge
          badgeContent="Most Popular"
          style={{ width: "30%", height: "100%" }}
        >
          <StandardContainerColumn
            style={{
              justifyContent: "space-between",
              alignItems: "flex-start",
              border: "2px solid #009ec6",
              padding: "24px",
            }}
          >
            <StandardContainerColumn
              style={{ alignItems: "flex-start", gap: "8px" }}
            >
              <p>Vekta Digital</p>
              <h1 style={{ fontWeight: "600" }}>Free</h1>

              <Divider style={{ width: "100%" }} />
            </StandardContainerColumn>

            <div className="pricig-body" style={{ padding: "0" }}>
              <ul>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Global Datasets (BASIC)
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Weather Analysis (BASIC)
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Elevation Analysis
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Data Search Filter (BASIC)
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Vekta Yield (BASIC)
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="grey"
                    size="lg"
                  />{" "}
                  Project Sharing
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="grey"
                    size="lg"
                  />{" "}
                  Project Dashboard
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="grey"
                    size="lg"
                  />{" "}
                  Custom Map Drawing
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="grey"
                    size="lg"
                  />{" "}
                  Multi-user Account
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="grey"
                    size="lg"
                  />{" "}
                  UK & Ireland Datasets
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="grey"
                    size="lg"
                  />{" "}
                  Site Comparison
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="grey"
                    size="lg"
                  />{" "}
                  Additional Modules
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="grey"
                    size="lg"
                  />{" "}
                  Engineering Design Support
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="grey"
                    size="lg"
                  />{" "}
                  Module Bundle Discounts
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="grey"
                    size="lg"
                  />{" "}
                  Early Access & Beta Testing
                </li>
              </ul>
            </div>
            <PrimeButton
              style={{ minWidth: "100%" }}
              onClick={() => setSidePanel({ open: false, type: null })}
            >
              Return to VD
            </PrimeButton>
          </StandardContainerColumn>
        </StyledBadge>

        <StyledBadge
          badgeContent={
            sidePanel.type === "deal" ? "Cyber Monday Deal" : "New Release"
          }
          type={sidePanel.type}
          style={{ width: "30%", height: "100%" }}
        >
          <StandardContainerColumn
            style={{
              justifyContent: "space-between",
              alignItems: "flex-start",
              border: "2px solid #009ec6",
              padding: "24px",
            }}
          >
            <StandardContainerColumn
              style={{ alignItems: "flex-start", gap: "8px" }}
            >
              <p>Vekta Digital +</p>

              <h1 style={{ fontWeight: "600" }}>
                {sidePanel.type !== "deal" ? (
                  <>
                    <sup>£</sup>
                    {paymentOption[frequency].price}
                    <sub style={{ fontSize: "x-small" }}>
                      /user per {frequency}
                    </sub>
                  </>
                ) : (
                  <>
                    Free
                    <sub style={{ fontSize: "x-small" }}>
                      /2nd Dec - 2nd Jan
                    </sub>
                  </>
                )}
              </h1>

              <Divider style={{ width: "100%" }} />
            </StandardContainerColumn>

            <div className="pricig-body" style={{ padding: "0" }}>
              <ul>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Global Datasets
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Weather Analysis
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Elevation Analysis
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Data Search
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Vekta Yield
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Project Sharing
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Project Dashboard
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Custom Map Drawing
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Multi-user Account (1 - 50)
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  UK & Ireland Datasets
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Site Comparison
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="lightgreen"
                    size="lg"
                  />{" "}
                  Additional Modules
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="grey"
                    size="lg"
                  />{" "}
                  Engineering Design Support
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="grey"
                    size="lg"
                  />{" "}
                  Module Bundle Discounts
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="grey"
                    size="lg"
                  />{" "}
                  Early Access & Beta Testing
                </li>
              </ul>
            </div>

            {activatedDeals > 100 || sidePanel.type !== "deal" ? (
              <PrimeButton
                style={{ minWidth: "100%" }}
                onClick={() => {
                  setLoading(true);
                  handleProductPurchase(paymentOption[frequency].priceID);
                }}
              >
                {!loading ? "Upgrade" : <BeatLoader color="white" />}
              </PrimeButton>
            ) : (
              // <div
              //   style={{
              //     display: "flex",
              //     flexDirection: "column",
              //     gap: "8px",
              //   }}
              // >
              //   <span>
              //     * Deal only applies to the first 100 <u>activated</u> product
              //     keys
              //   </span>
              <PrimeButton
                style={{ minWidth: "100%" }}
                onClick={() => {
                  const functions = getFunctions(app, "us-central1");
                  const dealProduct = httpsCallable(
                    functions,
                    "getDealProductKey"
                  );
                  setLoading(true);

                  dealProduct()
                    .then((result) => {
                      console.log("Product key sent");
                      setLoading(false);
                    })
                    .catch((error) => {
                      // Getting the Error details.
                      console.log(error.code);
                    });
                }}
              >
                {!loading ? "Activated Deal" : "...Sending Email"}
              </PrimeButton>
              // </div>
            )}
          </StandardContainerColumn>
        </StyledBadge>

        <StandardContainerColumn
          style={{
            justifyContent: "space-between",
            alignItems: "flex-start",
            border: "2px solid #009ec6",
            width: "30%",
            height: "100%",
            padding: "24px",
          }}
        >
          <StandardContainerColumn
            style={{ alignItems: "flex-start", gap: "8px" }}
          >
            {/* <img src={EnterpiseIcon} width="60px" /> */}
            <p>Enterprise</p>
            <h1 style={{ fontWeight: "600" }}>Contact Us</h1>

            <Divider style={{ width: "100%" }} />
          </StandardContainerColumn>

          <div className="pricig-body" style={{ padding: "0" }}>
            <ul>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                Global Datasets
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                Weather Analysis
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                Elevation Analysis
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                Data Search
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                Vekta Yield
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                Project Sharing
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                Project Dashboard
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                Custom Map Drawing
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                Multi-user Account (50+)
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                UK & Ireland Datasets
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                Site Comparison
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                Additional Modules
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                Engineering Design Support
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                Module Bundle Discounts
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="lightgreen"
                  size="lg"
                />{" "}
                Early Access & Beta Testing
              </li>
            </ul>
          </div>

          <PrimeButton onClick={() => {}} style={{ minWidth: "100%" }}>
            Contact US
          </PrimeButton>
        </StandardContainerColumn>
      </StandardContainerRow>
    </Container>
  );
}

export default InstallTool;
